import { BATR_BASE_URL } from "configurations";
import {
  getAsync,
  putAsync,
  postAsync,
  postFileAsync,
} from "helpers/apiHelper";
import { logger } from "helpers/debug/logger";
import Swal from "sweetalert2";

export const AdminApi = {
  getAdmin: async () => {
    return await getAsync(`${BATR_BASE_URL}/admin`);
  },
  updatePassword: async () => {
    return await putAsync(`${BATR_BASE_URL}/admin/password`);
  },
};

export const CompanyApi = {
  getCompany: async (id) => {
    return await getAsync(`${BATR_BASE_URL}/admin/company/${id}`);
  },
  getCompanies: async (page = 1, pageSize = 10, filters = {}) => {
    return await postAsync(
      `${BATR_BASE_URL}/admin/company?page=${page}&page_size=${pageSize}`,
      filters,
    );
  },
  updateCompany: async (id, body) => {
    return await putAsync(
      `${BATR_BASE_URL}/admin/company/${id}`,
      body,
    );
  }
};

export const EmployeeApi = {
  getEmployees: async (page = 1, pageSize = 10, filters = {}) => {
    return await postAsync(
      `${BATR_BASE_URL}/admin/employee?page=${page}&page_size=${pageSize}`,
      filters,
    );
  },
  makeFulltimeEmployeesExcel: async (formData) => {
    return await postFileAsync(
      `${BATR_BASE_URL}/admin/employee/make-fulltime-excel`,
      formData,
    );
  },
};

export const BatrApi = {
  makeReasonReport: async (body) => {
    return await postAsync(`${BATR_BASE_URL}/admin/batr-reason-report`, body);
  },
  getReasonReport: async (id, reported_year) => {
    return await getAsync(`${BATR_BASE_URL}/admin/batr-reason-report/company-id/${id}/reported-year/${reported_year}`);
  },
  updateReasonReport: async (id, body) => {
    return await putAsync(`${BATR_BASE_URL}/admin/batr-reason-report/${id}`, body);
  }
};
