export const SUCCESS = "success";
export const FAIL = "fail";

export const USER_ROLES = {
  ADMIN: "ADMIN",
};

export const AUTH_TYPE = {
  NONE: "NONE",
  SECURITY_CARD: "SECURITY_CARD",
  CERT_PFX: "CERT_PFX",
  CERT_DER_KEY: "CERT_DER_KEY",
};

export const CERT_FILE_EXTENSION = {
  PFX: ".pfx",
  DER: ".der",
  KEY: ".key",
  JPG: ".jpg",
  JPEG: ".jpeg",
  PNG: ".png",
};

export const DEFAULT_LIST_PAGE_SIZE = 10;
