const AppURLs = {
  // auth pages
  root: "/",
  login: "/login",
  logout: "/logout",
  forgotPassword: "/forgot-password",
  register: "/register",

  // common pages
  common: {
    root: "/",
    profile: "/profile",
    downloadS3: "/download-s3",
  },

  // error pages
  error: {
    authFailed: "/authfailed",
    page404: "/*",
  },

  // menu pages
  batr: {
    company: "/company",
    companyUpdate: "/company/update",
    employee: "/employee",
    employeeUpdate: "/employee/update",
    makeReasonReport: "/make-reason-report",
    makeFulltimeEmployeesExcel: "/employee/make-fulltime-employees",
  },
};

export default AppURLs;
