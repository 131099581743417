import React from "react";

/**
 * HorizontalLine 컴포넌트 정의
 *
 * @param {*} text    Text message
 * @returns
 */
const HorizontalLine = ({
  width = "100%",
  marginTop = "10px",
  marginBottom = "20px",
  text = "",
  textColor = "adb5bd",
  border = "1",
  color = "ced4da",
}) => {
  return (
    <div
      className="flex-all-center"
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          width: width,
          textAlign: "center",
          borderBottom: border + "px solid #" + color,
          lineHeight: "0.1em",
          margin: `${marginTop} 0 ${marginBottom}`,
        }}
      >
        {text && (
          <span
            style={{
              background: "#fff",
              padding: "0 10px",
              color: "#" + textColor,
            }}
          >
            {text}
          </span>
        )}
      </div>
    </div>
  );
};

export default HorizontalLine;
