import React from "react";
import { USER_ROLES } from "common/constants/constants";
import AppURLs from "routes/appUrls";

const ForgetPwd = React.lazy(() =>
  import("pages/Authentication/ForgetPassword"),
);
const Login = React.lazy(() => import("pages/Authentication/Login.jsx"));
const Logout = React.lazy(() => import("pages/Authentication/Logout"));
const Register = React.lazy(() => import("pages/Authentication/Register"));
const Home = React.lazy(() => import("pages/Home/index"));
const AdminProfile = React.lazy(() =>
  import("pages/Authentication/AdminProfile"),
);
const NotAuthorized = React.lazy(() => import("pages/Errors/NotAuthorized"));
const Pages404 = React.lazy(() => import("pages/Errors/Page404"));

const BatrCompany = React.lazy(() => import("pages/BATR/BatrCompany/index"));
const BatrUpdateCompany = React.lazy(() =>
  import("pages/BATR/BatrCompany/BatrUpdateCompany")
);
const BatrEmployee = React.lazy(() => import("pages/BATR/BatrEmployee"));
// const BatrEmployeeUpdate = React.lazy(() =>
  //   import("pages/BATR/Employee/BatrUpdateEmployee")
// );
const BatrMakeReasonReport = React.lazy(() => import("pages/BATR/BatrMakeReasonReport"));
const BatrMakeFulltimeEmployeesExcel = React.lazy(() =>
  import("pages/BATR/BatrEmployee/BatrMakeFulltimeEmployeesExcel"),
);

/**
 * Pages for authentication
 */
const authRoutes = [
  {
    path: AppURLs.login,
    component: Login,
  },
  {
    path: AppURLs.logout,
    component: Logout,
  },
  {
    path: AppURLs.forgotPassword,
    component: ForgetPwd,
  },
  {
    path: AppURLs.register,
    component: Register,
  },
];

/**
 * Common pages
 */
const commonRoutes = [
  // Common
  {
    path: AppURLs.common.profile,
    component: AdminProfile,
    roleAccess: [USER_ROLES.ADMIN],
  },
  {
    path: "/",
    component: Home,
    roleAccess: [USER_ROLES.ADMIN],
  },
];

const errorRoutes = [
  {
    path: AppURLs.error.authFailed,
    component: NotAuthorized,
  },
  {
    path: AppURLs.error.authFailed,
    component: Pages404,
  },
];

const batrRoutes = [
  /* 사업자 */
  {
    path: AppURLs.batr.company,
    component: BatrCompany,
    roleAccess: [USER_ROLES.ADMIN],
  },
  {
    path: AppURLs.batr.companyUpdate,
    component: BatrUpdateCompany,
    roleAccess: [
      USER_ROLES.ADMIN,
    ],
  },
  /* 근로자 관리 */
  {
    path: AppURLs.batr.employee,
    component: BatrEmployee,
    roleAccess: [USER_ROLES.ADMIN],
  },
  // {
    //   path: AppURLs.batr.employeeUpdate,
    //   component: BatrEmployeeUpdate,
    //   roleAccess: [
      //     USER_ROLES.ADMIN,
      //   ],
      // },
  /* 경정청구 사유서 생성 */
  {
    path: AppURLs.batr.makeReasonReport,
    component: BatrMakeReasonReport,
    roleAccess: [USER_ROLES.ADMIN],
  },
  {
    path: AppURLs.batr.makeFulltimeEmployeesExcel,
    component: BatrMakeFulltimeEmployeesExcel,
    roleAccess: [USER_ROLES.ADMIN],
  },
];

export { authRoutes, commonRoutes, errorRoutes, batrRoutes };
