import React from "react";
import { create } from "zustand";

export const useLoading = create((set) => ({
  loading: false,
  isTransparent: true,
  setLoadingState: (loading, isTransparent = true) =>
    set({
      loading,
      isTransparent,
    }),
}));

export const useModalStore = create((set) => ({
  isOpen: false,
  header: <span></span>,
  content: <span></span>,
  onConfirm: null,
  showModal: ({ header, onConfirm, content }) =>
    set({ isOpen: true, header, content, onConfirm }),
  closeModal: () => set((state) => ({ ...state, isOpen: false })),
}));

export const useRedirectSignal = create((set) => ({
  urlSignal: null,
  setUrlSignal: (urlSignal) => set({ urlSignal }),
}));
