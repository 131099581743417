import { getAsync, postAsync, putAsync } from "helpers/apiHelper";

export const LoginApi = {
  loginAdminUser: (data) => {
    return postAsync("admin/login", data);
  },
  // TODO: 추후 OTP 확인
  // checkOTP: (data) => {
  //   return postAsync("/admin/login-otp-auth", data);
  // },
  changePassword: (data) => {
    return putAsync("/admin/password", {
      password: data.password,
    });
  },
  refreshTokenAsync: () => {
    return getAsync("admin/refresh-token", {});
  },
};
