import { useEffect, useState } from "react";

import { canAccessRole } from "helpers/functionalHelper";
import useAdminInfoQuery from "queries/useAdminInfoQuery";

const RoleMiddleware = ({ children, roleAccess, history }) => {
  const [ready, setReady] = useState(false);
  const {
    adminInfoQuery: { data: userInfo },
  } = useAdminInfoQuery();

  useEffect(() => {
    if (userInfo) {
      if (canAccessRole(userInfo.role, roleAccess)) {
        setReady(true);
      }
    }
  }, [history, roleAccess, userInfo]);

  return (
    <>
      {ready > 0 ? (
        children
      ) : (
        <div className="page-content">
          <div className="container-fluid">Please Wait</div>
        </div>
      )}
    </>
  );
};

export default RoleMiddleware;
