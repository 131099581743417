import { useCallback } from "react";

const useToken = () => {
  const getAccessToken = useCallback(() => {
    return localStorage.getItem("accessToken");
  }, []);

  const getRefreshToken = useCallback(() => {
    return localStorage.getItem("refreshToken");
  }, []);

  const saveToken = useCallback((accessToken, refreshToken) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  }, []);

  const deleteToken = useCallback(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }, []);

  return { getAccessToken, getRefreshToken, saveToken, deleteToken };
};

export default useToken;
