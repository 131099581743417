import jwt_decode from "jwt-decode";
import React, { Suspense, useCallback, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { layoutTypes, themeSettings } from "common/config/layout";
import AppModal from "components/Common/AppModal/AppModal";
import PageLoading from "components/Common/PageLoading/PageLoading";
import HorizontalLayout from "components/HorizontalLayout/";
import NonAuthLayout from "components/NonAuthLayout";
import VerticalLayout from "components/VerticalLayout/";
import { logger } from "helpers/debug/logger";
import { LoginApi } from "helpers/api/loginApiService";
import useToken from "pages/Hooks/useToken";
import AppURLs from "routes/appUrls";
import {
  authRoutes,
  commonRoutes,
  errorRoutes,
  batrRoutes,
} from "routes/allRoutes";
import Authmiddleware from "routes/middleware/Authmiddleware";
import { ToastContainer } from "react-toastify";

const App = () => {
  const { getAccessToken, getRefreshToken, saveToken, deleteToken } =
    useToken();

  const checkToken = useCallback(
    async (timeLap) => {
      const accessToken = getAccessToken();
      if (!accessToken) {
        return;
      }

      const accessTimeout = jwt_decode(accessToken).exp * 1000;
      const timeLeft = accessTimeout - new Date().getTime();
      if (timeLeft <= 0) {
        // token expired, remove all and go to login
        logger("token expired");
        deleteToken();
        window.location.href = AppURLs.login;
        return;
      }

      if (timeLeft < timeLap) {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
          logger("there is no refreshToken");
          // window.location.href = AppURLs.login
          return;
        }

        const refreshTimeout = jwt_decode(refreshToken).exp * 1000;
        const refreshTimeLeft = refreshTimeout - new Date().getTime();
        if (refreshTimeLeft <= 0) {
          logger("refreshToken expired");
          deleteToken();
          window.location.href = AppURLs.login;
          return;
        }

        // call refresh
        try {
          logger("refreshing token");
          const response = await LoginApi.refreshTokenAsync(refreshToken);
          if (response.status === "success") {
            saveToken(response.data.accessToken, response.data.refreshToken);
            logger("Token refresh succeeded");
            return;
          }
        } catch (err) {
          logger("Token refresh failed");
        }
      }
    },
    [deleteToken, getAccessToken, getRefreshToken, saveToken],
  );

  useEffect(() => {
    const timeLap = 600_000; // 10 minutes
    checkToken(timeLap);
    const intervalId = setInterval(() => {
      checkToken(timeLap);
    }, timeLap / 3);
    return () => {
      clearInterval(intervalId);
    };
  }, [checkToken]);

  const getLayout = () => {
    let layoutCls = VerticalLayout;
    switch (themeSettings.layoutType) {
      case layoutTypes.HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  const Layout = getLayout();

  return (
    <React.Fragment>
      <Router>
        {/**
         * React.lazy()를 사용해서 동적으로 컴포넌트를 불러오는 코드를 추가한 경우
         * <Suspense/>를 적용해서 로딩하는 동안 예비 콘텐츠를 제공 - 에러 방지
         */}
        <Suspense fallback={<PageLoading isLoading isTransparent={false} />}>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {batrRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                roleAccess={route.roleAccess}
                key={idx}
                isAuthProtected={true}
                exact={route.exact === undefined ? true : route.exact}
              />
            ))}

            {commonRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                roleAccess={route.roleAccess}
                key={idx}
                isAuthProtected={true}
                exact={route.exact === undefined ? true : route.exact}
              />
            ))}

            {/* Error route should be the last one */}
            {errorRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
          </Switch>
        </Suspense>
      </Router>

      {/* React hook 으로 제어할 global 컴포넌트 */}
      <ToastContainer autoClose={2000} hideProgressBar closeOnClick />
      <AppModal />
      <PageLoading />
    </React.Fragment>
  );
};

export default App;
