import { logger } from "helpers/debug/logger";

/**
 * Make pagination array
 *
 * @param {*} totalPage
 * @param {*} currentPage
 * @returns   pagination array
 */
export const makePaginationArray = (totalPage, currentPage) => {
  let start = 1,
    end = 10,
    arr = [];
  if (totalPage <= 9) {
    start = 1;
    end = totalPage || 1;
  } else {
    if (currentPage <= 5) {
      start = 1;
      end = 9;
    } else if (currentPage + 4 >= totalPage) {
      start = totalPage - 8;
      end = totalPage;
    } else {
      start = currentPage - 4;
      end = currentPage + 4;
    }
  }

  for (let i = start; i <= end; i++) {
    arr.push(i);
  }

  return arr;
};

/**
 * Scroll to top
 */
export function scrollToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}

/*************************************************************************
 * Theme customizing utils
 *************************************************************************/
/**
 * Changes the body attribute
 */
const changeBodyAttribute = (attribute, value) => {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
};

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
const manageBodyClass = (cssClass, action = "toggle") => {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }
  return true;
};

/**
 * Changes the topbar theme
 * @param {*} param0
 */
const changeTopbarTheme = ({ payload: theme }) => {
  try {
    changeBodyAttribute("data-topbar", theme);
  } catch (error) {
    logger(error.message);
  }
};

/**
 * Changes the layout type
 * @param {*} param0
 */
const changeLayout = ({ payload: layout }) => {
  try {
    if (layout === "horizontal") {
      changeTopbarTheme("dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-sidebar-image");
      document.body.removeAttribute("data-sidebar-size");
    } else {
      changeTopbarTheme("light");
    }
    changeBodyAttribute("data-layout", layout);
  } catch (error) {
    logger(error.message);
  }
};

/**
 * Changes the layout mode
 * @param {*} param0
 */
const changeLayoutMode = ({ payload: mode }) => {
  try {
    changeBodyAttribute("data-layout-mode", mode);
  } catch (error) {
    logger(error.message);
  }
};

/**
 * Changes the layout width
 * @param {*} param0
 */
const changeLayoutWidth = ({ payload: width }) => {
  try {
    if (width === "boxed") {
      changeLeftSidebarType("icon");
      changeBodyAttribute("data-layout-size", width);
      changeBodyAttribute("data-layout-scrollable", false);
    } else if (width === "scrollable") {
      changeLeftSidebarType("default");
      changeBodyAttribute("data-layout-scrollable", true);
    } else {
      changeLeftSidebarType("default");
      changeBodyAttribute("data-layout-size", width);
      changeBodyAttribute("data-layout-scrollable", false);
    }
  } catch (error) {
    logger(error.message);
  }
};

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
const changeLeftSidebarTheme = ({ payload: theme }) => {
  try {
    changeBodyAttribute("data-sidebar", theme);
  } catch (error) {
    logger(error.message);
  }
};

/**
 * Changes the left sidebar theme Image
 * @param {*} param0
 */
const changeLeftSidebarThemeImage = ({ payload: theme }) => {
  try {
    changeBodyAttribute("data-sidebar-image", theme);
  } catch (error) {
    logger(error.message);
  }
};

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
const changeLeftSidebarType = ({ payload: { sidebarType, isMobile } }) => {
  try {
    switch (sidebarType) {
      case "compact":
        changeBodyAttribute("data-sidebar-size", "small");
        manageBodyClass("sidebar-enable", "remove");
        manageBodyClass("vertical-collpsed", "remove");
        break;
      case "icon":
        changeBodyAttribute("data-sidebar-size", "");
        changeBodyAttribute("data-keep-enlarged", "true");
        manageBodyClass("vertical-collpsed", "add");
        break;
      case "condensed":
        manageBodyClass("sidebar-enable", "add");
        if (window.screen.width >= 998) {
          manageBodyClass("vertical-collpsed", "remove");
          manageBodyClass("sidebar-enable", "remove");
          manageBodyClass("vertical-collpsed", "add");
          manageBodyClass("sidebar-enable", "add");
        } else {
          manageBodyClass("sidebar-enable", "add");
          manageBodyClass("vertical-collpsed", "add");
        }
        break;
      default:
        changeBodyAttribute("data-sidebar-size", "");
        manageBodyClass("sidebar-enable", "remove");
        if (!isMobile) manageBodyClass("vertical-collpsed", "remove");
        break;
    }
  } catch (error) {
    logger(error.message);
  }
};

/**
 * Toggles the rightsidebar
 */
const toggleRightSidebar = () => {
  try {
    manageBodyClass("right-bar-enabled");
  } catch (error) {
    logger(error.message);
  }
};

/**
 * Show the rightsidebar
 */
const showRightSidebar = () => {
  try {
    manageBodyClass("right-bar-enabled", "add");
  } catch (error) {
    logger(error.message);
  }
};

/**
 * Hides the rightsidebar
 */
const hideRightSidebar = () => {
  try {
    manageBodyClass("right-bar-enabled", "remove");
  } catch (error) {
    logger(error.message);
  }
};

export {
  changeBodyAttribute,
  manageBodyClass,
  changeTopbarTheme,
  changeLayout,
  changeLayoutMode,
  changeLayoutWidth,
  changeLeftSidebarTheme,
  changeLeftSidebarThemeImage,
  changeLeftSidebarType,
  toggleRightSidebar,
  showRightSidebar,
  hideRightSidebar,
};
