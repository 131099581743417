export const AppQueryKey = {
  global: {
    appNotice: "app_notice",
  },
  auth: {
    forgetPassword: "forget_password",
  },
  admin: {
    getAdmin: "get_user",
  },
  batr: {
    getCompanies: "get_companies",
    getCompany: "get_company",
    getEmployees: "get_employees",
  },
};
