import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppQueryKey } from "common/constants/queryKey";
import { AdminApi } from "helpers/api/batrApiService";
import { useCallback } from "react";

const queryKey = [AppQueryKey.admin.getAdmin];

const useAdminInfoQuery = (isLoginSuccess = true) => {
  const queryClient = useQueryClient();

  const adminInfoQuery = useQuery(
    queryKey,
    async () => {
      const res = await AdminApi.getAdmin();
      if (res.status === "success") {
        return res.data;
      }
      return Promise.reject("Fail");
    },
    {
      enabled: isLoginSuccess,
      staleTime: 1000 * 60 * 30, // 30 minutes
      cacheTime: 1000 * 60 * 60, // 60 minutes
    },
  );

  const clearAdminInfo = useCallback(() => {
    queryClient.setQueryData(queryKey, {});
    queryClient.invalidateQueries(queryKey); // 쿼리를 무효화함 (stale 상태로 변경)
  }, [queryClient]);

  const updateAdminPassword = useMutation({
    mutationFn: async ({ data, callbackFn = () => {} }) => {
      const res = await AdminApi.updatePassword(data);
      if (res.status === "success") {
        const admin = res.data.user;
        const oldDataset = queryClient.getQueryData(queryKey);
        const newDataset = {
          ...oldDataset,
          ...admin,
        };
        queryClient.setQueryData(queryKey, newDataset);
        callbackFn(true, "success");
        return newDataset;
      } else {
        callbackFn(false, res.status);
      }
      return Promise.reject("updateAdminPassword failed");
    },
  });

  return {
    adminInfoQuery,
    updateAdminPassword,
    clearAdminInfo,
  };
};

export default useAdminInfoQuery;
