// import http from "https";
// import https from "https";
import axios, { HttpStatusCode } from "axios";
import { camelize, decamelize } from "@ridi/object-case-converter";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import Swal from "sweetalert2";

import { BATR_BASE_URL } from "configurations";
import AppURLs from "routes/appUrls";
import { logger } from "helpers/debug/logger";
import { REFRESH_TOKEN } from "helpers/urlHelper";

// const httpAgent = new http.Agent({ keepAlive: true });
// const httpsAgent = new https.Agent({ keepAlive: true });

// apply base url for axios
const axiosApi = axios.create({
  baseURL: BATR_BASE_URL,
  //   httpAgent,
  //   httpsAgent,
});

axiosApi.interceptors.request.use((config) => {
  config.data = decamelize(config.data, { recursive: true });

  const accessToken = localStorage.getItem("accessToken");

  if (accessToken && config.headers) {
    config.headers.Authorization = accessToken;
  }
  return config;
});

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) =>
  axios
    .get(REFRESH_TOKEN, {
      // clientId: localStorage.getItem("clientId"),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
      },
      // refreshToken: localStorage.getItem("refreshToken")
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem(
        "accessToken",
        tokenRefreshResponse.data.accessToken,
      );
      localStorage.setItem(
        "refreshToken",
        tokenRefreshResponse.data.refreshToken,
      );
      // failedRequest.response.config.headers["Authorization"] =  // TODO: 확인
      if (failedRequest.headers) {
        failedRequest.headers.Authorization =
          tokenRefreshResponse.data.accessToken;
      } else {
        throw new Error("failedRequest에 headers 객체가 없음");
      }

      return Promise.resolve();
    })
    .catch(() => {
      window.location.href = AppURLs.logout;
    });

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axiosApi, refreshAuthLogic);

axiosApi.interceptors.response.use(
  (response) => {
    response.data = camelize(response.data, { recursive: true });
    return response;
  },
  (error) => Promise.reject(error),
);

// export function get(url, config = {}) {
//   return axiosApi
//     .get(url, {
//       ...config,
//     })
//     .then(response => response.data);
// }

export async function getAsync(url, config = {}) {
  return axiosApi
    .get(url, {
      ...config,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(error, "From catch");
      return {
        status: HttpStatusCode.InternalServerError,
        message: error.message,
      }; // TODO: 확인
      // return { status: FAIL, message: error };
    });
}

// export function post(url, data, config = {}) {
//   return axiosApi
//     .post(
//       url,
//       {
//         ...data,
//       },
//       {
//         ...config,
//       }
//     )
//     .then(response => response.data);
// }

export async function postAsync(url, data, config = {}) {
  return axiosApi
    .post(
      url,
      {
        ...data,
      },
      {
        ...config,
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error, "From catch");
      return {
        status: HttpStatusCode.InternalServerError,
        message: error.message,
      }; // TODO: 확인
      // return { status: FAIL, message: error };
    });
}

// export function postFile(url, data, config = {}) {
//   return axiosApi
//     .post(url, data, {
//       ...config,
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//     })
//     .then(response => response.data);
// }

export async function postFileAsync(url, data, config = {}) {
  return axiosApi
    .post(url, data, {
      ...config,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    // .catch((error) => {
    //   console.error(error, "From catch");
    //   return {
    //     status: HttpStatusCode.InternalServerError,
    //     message: error.message,
    //   }; // TODO: 확인
    //   // return { status: FAIL, message: error };
    // });
}

// export function put(url, data, config = {}) {
//   return axiosApi
//     .put(
//       url,
//       {
//         ...data,
//       },
//       {
//         ...config,
//       }
//     )
//     .then(response => response.data)
//     .catch(error => {
//       console.error(error, "From catch");
//       return { status: FAIL, message: error };
//     });
// }

export async function putAsync(url, data, config = {}) {
  return axiosApi
    .put(
      url,
      {
        ...data,
      },
      {
        ...config,
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      console.error(error, "From catch");
      return {
        status: HttpStatusCode.InternalServerError,
        message: error.message,
      }; // TODO: 확인
      // return { status: FAIL, message: error };
    });
}

// export function putFile(url, data, config = {}) {
//   return axiosApi
//     .put(url, data, {
//       ...config,
//     })
//     .then(response => response.data);
// }

// export function putFileAsync(url, data, config = {}) {
//   return axiosApi
//     .put(url, data, {
//       ...config,
//     })
//     .then(response => response.data)
//     .catch((error) => {
//       logger(error);
//       return { status: FAIL, message: error };
//     });
// }

// export function del(url, config = {}) {
//   return axiosApi
//     .delete(url, {
//       ...config,
//     })
//     .then(response => ({ status: "success" }))
//     .catch((error) => {
//       console.log(error);
//       console.log(error?.response?.data);
//       return error?.response?.data ?? { status: FAIL};
//     });
// }

export async function delAsync(url, config = {}) {
  return axiosApi
    .delete(url, {
      ...config,
    })
    .then((response) => response.data)
    .catch((error) => {
      logger(error);
      return {
        status: HttpStatusCode.InternalServerError,
        message: error.message,
      }; // TODO: 확인
      // return { status: FAIL, message: error };
    });
}

export async function execute(apiPromise, callback, useErrorPopup = true) {
  let rawRes = {
    status: HttpStatusCode.Ok,
  };
  await apiPromise
    .then((res) => {
      if (res.data.status === HttpStatusCode.Ok) {
        // TODO: 확인
        // if (res.status === HttpResponse) {
        rawRes = { status: res.status, data: res.data };
        // rawRes = { status: res.status, data: res.data };
      } else {
        rawRes = {
          status: res.status,
          message: res.data.message ?? "API 처리중 문제가 발생하였습니다.", // TODO: 확인
          // message: res.message ?? "API 처리중 문제가 발생하였습니다.",
        };
        if (useErrorPopup) {
          Swal.fire({
            icon: "error",
            title: "API 에러",
            text: res.data.message ?? "API 처리중 문제가 발생하였습니다.", // TODO: 확인
            // text: res.message ?? "API 처리중 문제가 발생하였습니다.",
            confirmButtonText: "확인",
          });
          // }).then(result => {});
        }
      }
    })
    .catch((err) => {
      logger(err);
      rawRes = {
        status: HttpStatusCode.InternalServerError, // TODO: 확인
        // status: FAIL,
        message: err.message ?? "서버 호출중 문제가 발생하였습니다.",
        data: err.response?.data, // TODO: 확인
        // data: err.response?.data?.data,
      };
      if (useErrorPopup) {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: err.message ?? "서버 호출중 문제가 발생하였습니다.",
          confirmButtonText: "확인",
        });
      }
    })
    .finally(() => {
      callback(rawRes);
    });
}
